import { FC } from 'react';
import Box from '@material-ui/core/Box';

const Blockquote: FC = (props) => (
  <Box
    style={{
      borderLeft: '4px solid lightgrey',
      padding: 8,
      borderRadius: '4px 0 0 4px',
    }}
    mb={2}
    {...props}
  />
);

export default Blockquote;

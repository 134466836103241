import { ChangeEvent, FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Yaml from 'yaml';

import Schema from './Schema';

interface JsonSchemaDocProps {
  rawSchema: object;
}

function download(filename: string, text: string): void {
  const element = document.createElement('a');

  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const JsonSchemaDoc: FC<JsonSchemaDocProps> = (props) => {
  const [value, setValue] = useState('1');

  const handleChange = (_: ChangeEvent<{}>, newValue: string): void => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} aria-label="Json Schema Doc">
        <Tab label="Schema" value="1" />
        <Tab label="Raw JSON" value="2" />
      </TabList>
      <TabPanel value="1">
        <Schema rawSchema={props.rawSchema} topLevel />
      </TabPanel>
      <TabPanel value="2">
        <Box
          className="gatsby-highlight"
          data-language="json"
          overflow="hidden"
        >
          <pre className="language-json">
            <code className="language-json">
              {JSON.stringify(props.rawSchema, undefined, 2)}
            </code>
          </pre>
        </Box>
        <Button
          onClick={(): void =>
            download('schema.json', JSON.stringify(props.rawSchema, null, 2))
          }
          startIcon={<DownloadIcon />}
        >
          JSON
        </Button>
        <Button
          onClick={(): void =>
            download('schema.yaml', Yaml.stringify(props.rawSchema))
          }
          startIcon={<DownloadIcon />}
        >
          YAML
        </Button>
      </TabPanel>
    </TabContext>
  );
};

export default JsonSchemaDoc;

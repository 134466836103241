import { FC } from 'react';

import FieldLabel from './FieldLabel';

interface FieldConstraintsProps {
  constraints: string[];
}

const FieldConstraints: FC<FieldConstraintsProps> = (props) => {
  const { constraints } = props;

  if (constraints.length === 0) return null;

  return (
    <span>
      {' '}
      {constraints.map((constraint) => (
        <FieldLabel key={constraint} variant="exampleValue">
          {' '}
          {constraint}{' '}
        </FieldLabel>
      ))}
    </span>
  );
};

export default FieldConstraints;

import { FC } from 'react';
import Badge from '@material-ui/core/Badge';

import { makeStyles } from '@material-ui/core/styles';
import CustomMarkdown from '../CustomMarkdown';
import EnumValues from './EnumValues';
import FieldConstraints from './FieldConstraints';
import FieldLabel from './FieldLabel';
import { PreparedFieldSchema } from './types';
import Examples from './Examples';
import useSchemaIndex from '../../hooks/useSchemaIndex';
import SchemaReference from './SchemaReference';

interface FieldDetailsProps {
  field: PreparedFieldSchema;
}

// eslint-disable-next-line complexity
const FieldDetails: FC<FieldDetailsProps> = ({ field }) => {
  const schemaEntries = useSchemaIndex();
  const { schema, description, deprecated } = field;
  const {
    examples,
    $ref,
    constant,
    constraints,
    displayFormat,
    displayType,
    isCircular,
    nullable,
    pattern,
    title,
    typePrefix,
  } = schema;
  const refSchema = $ref
    ? schemaEntries.find((entry) => {
        const parsedRef = `${$ref.replace('#/', '')}/`;
        // this matches 'workflowConfig' and 'stepConfig' schemas
        const target = parsedRef.endsWith('Config/') ? 'config/' : parsedRef;

        return entry.slug.endsWith(target);
      })
    : null;

  return (
    <div>
      <div>
        <FieldLabel variant="typePrefix">{typePrefix}</FieldLabel>
        <FieldLabel variant="typeName">{displayType}</FieldLabel>
        {displayFormat && (
          <FieldLabel variant="typeName">
            {' '}
            &lt;
            {displayFormat}
            &gt;{' '}
          </FieldLabel>
        )}
        {title && <FieldLabel variant="typeTitle"> ({title})</FieldLabel>}
        <FieldConstraints constraints={constraints} />
        {nullable && <FieldLabel variant="nullable">nullable</FieldLabel>}
        {isCircular && <FieldLabel variant="recursive">recursive</FieldLabel>}
      </div>
      {deprecated && (
        <div>
          <Badge color="error" badgeContent={'deprecated'} />
        </div>
      )}
      {pattern && (
        <div>
          <FieldLabel variant="typePrefix">pattern: </FieldLabel>
          <FieldLabel variant="pattern">{pattern}</FieldLabel>
        </div>
      )}
      <EnumValues type={schema.type} values={schema.enum} />
      {constant && (
        <div>
          <FieldLabel variant="typePrefix">constant: </FieldLabel>
          <FieldLabel variant="exampleValue">{constant.toString()}</FieldLabel>
        </div>
      )}
      {schema.default !== undefined && (
        <div>
          <FieldLabel variant="typePrefix">default: </FieldLabel>
          <FieldLabel variant="exampleValue">
            {schema.default.toString()}
          </FieldLabel>
        </div>
      )}
      {schema.type === 'reference' || schema.items?.type === 'reference' ? (
        <SchemaReference reference={$ref!} />
      ) : null}
      {description && <CustomMarkdown>{description}</CustomMarkdown>}
      {!description && $ref && refSchema && (
        // Display first paragraph from the description of the referenced
        // schema, if field property has no description of its own.
        <CustomMarkdown>
          {JSON.parse(refSchema.json)
            ?.description?.split('\n\n')[0]
            ?.concat('\n\n') ?? ''}
        </CustomMarkdown>
      )}
      {examples && <Examples examples={examples} />}
    </div>
  );
};

export default FieldDetails;

import { FC, Fragment } from 'react';

import FieldLabel from './FieldLabel';

interface EnumValuesProps {
  type: string;
  values: string[];
}

const EnumValues: FC<EnumValuesProps> = (props) => {
  const { values, type } = props;

  if (!values.length) return null;

  return (
    <Fragment>
      <FieldLabel variant="typePrefix">
        {type === 'array' ? 'one of ' : ''}
        {values.length === 1 ? 'must be: ' : 'one of: '}
      </FieldLabel>{' '}
      {values.map((value: string, idx: number) => (
        <Fragment key={idx}>
          <FieldLabel variant="exampleValue">{value}</FieldLabel>{' '}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default EnumValues;

import { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import Field from './Field';

import CustomMarkdown from '../CustomMarkdown';
import { Schema as SchemaType } from './types';

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(2),
  },
  propertiesTable: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    textAlign: 'left',
    '& > tr': {
      verticalAlign: 'middle',
    },
    '& .innerPropWrap, & .innerPropWrap .innerPropWrap .innerPropWrap, & .innerPropWrap .innerPropWrap .innerPropWrap .innerPropWrap .innerPropWrap': {
      margin: theme.spacing(2),
      marginRight: 0,
      backgroundColor: darken(theme.palette.background.paper, 0.05),
    },
    '& .innerPropWrap .innerPropWrap, & .innerPropWrap .innerPropWrap .innerPropWrap .innerPropWrap, & .innerPropWrap .innerPropWrap .innerPropWrap .innerPropWrap .innerPropWrap .innerPropWrap': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  propertiesTableCaption: {
    textAlign: 'left',
    fontSize: '0.9em',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
  },
}));

interface ObjectSchemaProps {
  schema: SchemaType;
  topLevel?: boolean;
}

const ObjectSchema: FC<ObjectSchemaProps> = (props) => {
  const { schema, topLevel, children } = props;
  const classes = useStyles();

  return (
    <div>
      {topLevel && schema.description ? (
        <div className={classes.description}>
          <CustomMarkdown>{schema.description}</CustomMarkdown>
        </div>
      ) : null}
      <table className={classes.propertiesTable}>
        <Typography variant="caption" component="caption" align="left">
          {schema.title}
        </Typography>
        <tbody>
          {schema.fields &&
            schema.fields.map((field, i, arr) => (
              <Field
                key={`field-${field.name}`}
                field={field}
                isLast={i === arr.length - 1}
              />
            ))}
        </tbody>
      </table>
      {children}
    </div>
  );
};

export default ObjectSchema;

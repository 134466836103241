import { compact, last, startCase } from 'lodash';

import { FieldSchema, PreparedFieldSchema, RawSchema, Schema } from './types';

const schemaKeywordTypes: { [index: string]: string } = {
  $ref: 'reference',

  multipleOf: 'number',
  maximum: 'number',
  exclusiveMaximum: 'number',
  minimum: 'number',
  exclusiveMinimum: 'number',

  maxLength: 'string',
  minLength: 'string',
  pattern: 'string',

  items: 'array',
  maxItems: 'array',
  minItems: 'array',
  uniqueItems: 'array',

  maxProperties: 'object',
  minProperties: 'object',
  required: 'object',
  additionalProperties: 'object',
  properties: 'object',
};

export function getDisplayType(schema: Schema): string | undefined {
  if (schema.title) return schema.title;
  if (schema.$ref) return startCase(last(schema.$ref.split('/')));

  return schema.displayType || schema.type;
}

export function detectType(schema: RawSchema) {
  if (schema.type !== undefined) {
    return schema.type;
  }

  for (const key in schemaKeywordTypes) {
    const type = schemaKeywordTypes[key];

    if (schema[key] !== undefined) {
      return type;
    }
  }

  return 'any';
}

export function isPrimitiveType(schema: RawSchema, type: string) {
  if (schema.oneOf !== undefined || schema.anyOf !== undefined) {
    return false;
  }

  if (type === 'object') {
    return schema.properties !== undefined
      ? Object.keys(schema.properties).length === 0
      : schema.additionalProperties === undefined;
  }

  if (type === 'array') {
    if (schema.items === undefined) {
      return true;
    }
    return false;
  }

  return true;
}

export function pluralizeType(displayType: string) {
  return displayType
    .split(' or ')
    .map((type) =>
      type.replace(
        /^(string|object|number|integer|array|boolean)s?( ?.*)/,
        '$1s$2'
      )
    )
    .join(' or ');
}

function humanizeMultipleOfConstraint(multipleOf?: number) {
  if (multipleOf === undefined) {
    return;
  }

  const stringifiedMultipleOf = multipleOf.toString(10);

  if (!/^0\.0*1$/.test(stringifiedMultipleOf)) {
    return `multiple of ${stringifiedMultipleOf}`;
  }

  return `decimal places <= ${stringifiedMultipleOf.split('.')[1].length}`;
}

function humanizeRangeConstraint(
  description: string,
  min?: number,
  max?: number
) {
  let stringRange;

  if (min !== undefined && max !== undefined) {
    if (min === max) {
      stringRange = `${min} ${description}`;
    } else {
      stringRange = `[ ${min} .. ${max} ] ${description}`;
    }
  } else if (max !== undefined) {
    stringRange = `<= ${max} ${description}`;
  } else if (min !== undefined) {
    if (min === 1) {
      stringRange = 'non-empty';
    } else {
      stringRange = `>= ${min} ${description}`;
    }
  }

  return stringRange;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, max-statements
function humanizeNumberConstraint(schema: RawSchema) {
  let numberRange;

  if (schema.minimum !== undefined && schema.maximum !== undefined) {
    numberRange = schema.exclusiveMinimum ? '( ' : '[ ';
    numberRange += schema.minimum;
    numberRange += ' .. ';
    numberRange += schema.maximum;
    numberRange += schema.exclusiveMaximum ? ' )' : ' ]';
  } else if (schema.maximum !== undefined) {
    numberRange = schema.exclusiveMaximum ? '< ' : '<= ';
    numberRange += schema.maximum;
  } else if (schema.minimum !== undefined) {
    numberRange = schema.exclusiveMinimum ? '> ' : '>= ';
    numberRange += schema.minimum;
  }

  return numberRange;
}

export function humanizeConstraints(schema: RawSchema) {
  const res = [
    humanizeRangeConstraint('characters', schema.minLength, schema.maxLength),
    humanizeRangeConstraint('items', schema.minItems, schema.maxItems),
    humanizeMultipleOfConstraint(schema.multipleOf),
    humanizeNumberConstraint(schema),
  ];

  return compact(res);
}

// eslint-disable-next-line max-statements, complexity
export function prepareSchema(rawSchema: RawSchema, pointer = '') {
  const type = rawSchema.type || detectType(rawSchema);
  const schema: Schema = {
    pointer,
    title: rawSchema.title,
    isCircular: !!rawSchema['x-circular-ref'],
    description: rawSchema.description || '',
    type,
    format: rawSchema.format,
    $ref: rawSchema.$ref,
    nullable: !!rawSchema.nullable,
    enum: rawSchema.enum || [],
    examples: rawSchema.examples,
    deprecated: !!rawSchema.deprecated,
    pattern: rawSchema.pattern,
    externalDocs: rawSchema.externalDocs,
    constraints: humanizeConstraints(rawSchema),
    displayType: type,
    displayFormat: rawSchema.format,
    isPrimitive: isPrimitiveType(rawSchema, type),
    default: rawSchema.default,
    readOnly: !!rawSchema.readOnly,
    writeOnly: !!rawSchema.writeOnly,
    constant: rawSchema.const,
  };

  if (schema.isCircular) {
    return schema;
  }

  if (rawSchema.oneOf !== undefined) {
    const [oneOf, displayType] = initOneOf(rawSchema.oneOf, schema);

    schema.oneOf = oneOf;
    schema.displayType = displayType;
    schema.oneOfType = 'one of';
    if (rawSchema.anyOf !== undefined || rawSchema.allOf !== undefined) {
      console.warn(
        `oneOf and anyOf or allOf are not supported on the same level. Skipping anyOf at ${schema.pointer}`
      );
    }
    return schema;
  }

  if (rawSchema.anyOf !== undefined) {
    const [oneOf, displayType] = initOneOf(rawSchema.anyOf, schema);

    schema.oneOf = oneOf;
    schema.displayType = displayType;
    schema.oneOfType = 'any of';
    if (rawSchema.allOf !== undefined) {
      console.warn(
        `anyOf and allOf are not supported on the same level. Skipping anyOf at ${schema.pointer}`
      );
    }
    return schema;
  }

  if (rawSchema.allOf !== undefined) {
    const [oneOf, displayType] = initOneOf(rawSchema.allOf, schema);

    schema.oneOf = oneOf;
    schema.displayType = displayType;
    schema.oneOfType = 'all of';
    return schema;
  }

  if (schema.type === 'object') {
    schema.fields = buildFields(rawSchema, schema.pointer);
  } else if (schema.type === 'array' && rawSchema.items) {
    schema.items = prepareSchema(rawSchema.items, `${schema.pointer}/items`);
    schema.$ref = schema.items.$ref;
    schema.displayType = pluralizeType(schema.items.displayType);
    schema.displayFormat = schema.items.format;
    schema.typePrefix = 'array of ';
    schema.title = schema.title || schema.items.title;
    schema.isPrimitive = schema.items.isPrimitive;
    if (
      schema.example === undefined &&
      schema.examples === undefined &&
      schema.items.example !== undefined
    ) {
      schema.example = [schema.items.example];
    }
    if (schema.items.isPrimitive) {
      schema.enum = schema.items.enum;
    }
  }

  return schema;
}

function initOneOf(oneOf: RawSchema[], scm: Schema): [Schema[], string] {
  const oneOfMapped = oneOf.map((variant, idx) => {
    const schema = prepareSchema(variant, `${scm.pointer}/oneOf/${idx}`);

    return schema;
  });

  const displayType = oneOfMapped
    .map((schema) => {
      let name =
        // schema.typePrefix +
        schema.title
          ? `${schema.title} (${schema.displayType})`
          : schema.displayType;

      if (name.indexOf(' or ') > -1) {
        name = `(${name})`;
      }
      return name;
    })
    .join(' or ');

  return [oneOfMapped, displayType];
}

// eslint-disable-next-line max-statements, complexity
export function prepareField(info: FieldSchema, pointer: string) {
  const field: PreparedFieldSchema = {
    kind: info.kind || 'field',
    name: info.name,
    in: info.in,
    required: !!info.required,
  };

  let fieldSchema = info.schema;
  let serializationMime = '';

  if (!fieldSchema && info.in && info.content) {
    serializationMime = Object.keys(info.content)[0];
    fieldSchema =
      info.content[serializationMime] && info.content[serializationMime].schema;
  }

  field.schema = prepareSchema(fieldSchema || {}, pointer);
  field.description =
    info.description === undefined
      ? field.schema.description || ''
      : info.description;
  field.examples = info.examples || field.schema.examples;

  if (serializationMime) {
    field.serializationMime = serializationMime;
  } else if (info.style) {
    field.style = info.style;
  } else if (field.in) {
    field.style = 'form';
  }

  field.explode = !!info.explode;

  field.deprecated =
    info.deprecated === undefined ? !!field.schema.deprecated : info.deprecated;

  return field;
}

export function buildFields(schema: RawSchema, pointer: string) {
  const props = schema.properties || {};
  const additionalProps = schema.additionalProperties;
  const defaults = schema.default || {};
  const fields = Object.keys(props || []).map((fieldName) => {
    let field = props[fieldName];

    if (!field) {
      console.warn(
        `Field "${fieldName}" is invalid, skipping.\n Field must be an object but got ${typeof field} at "${pointer}"`
      );
      field = {};
    }

    const required =
      schema.required === undefined
        ? false
        : schema.required.indexOf(fieldName) > -1;

    return prepareField(
      {
        name: fieldName,
        required,
        schema: {
          ...field,
          default:
            field.default === undefined ? defaults[fieldName] : field.default,
        },
      },
      `${pointer}/properties/${fieldName}`
    );
  });

  if (typeof additionalProps === 'object' || additionalProps === true) {
    fields.push(
      prepareField(
        {
          name: (typeof additionalProps === 'object'
            ? additionalProps['x-additionalPropertiesName'] || 'property name'
            : 'property name'
          ).concat('*'),
          required: false,
          schema: additionalProps === true ? {} : additionalProps,
          kind: 'additionalProperties',
        },
        `${pointer}/additionalProperties`
      )
    );
  }

  return fields;
}

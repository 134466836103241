import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import { Schema as SchemaType } from './types';

import CustomMarkdown from '../CustomMarkdown';
import FieldLabel from './FieldLabel';
import Schema from './Schema';

const useStyles = makeStyles((_) => ({
  arrayOpeningLabel: {
    fontSize: '.9em',
    fontFamily: '"Roboto Mono", monospace',
    '&::after': {
      content: '" ["',
    },
  },
  arrayClosingLabel: {
    fontSize: '.9em',
    fontFamily: '"Roboto Mono", monospace',
    '&::after': {
      content: '" ["',
    },
  },
}));

interface ArraySchemaProps {
  schema: SchemaType;
  topLevel?: boolean;
}

const ArraySchema: FC<ArraySchemaProps> = (props) => {
  const { schema, topLevel, children } = props;
  const itemsSchema = schema.items;
  const classes = useStyles();

  return (
    <div>
      {topLevel && schema.description && (
        <div>
          <CustomMarkdown>{schema.description}</CustomMarkdown>
        </div>
      )}
      <div className={classes.arrayOpeningLabel}>Array</div>
      {itemsSchema ? (
        <Box paddingLeft={1}>
          <Schema schema={itemsSchema} />
        </Box>
      ) : (
        <FieldLabel variant="typeName">any</FieldLabel>
      )}
      <div className={classes.arrayClosingLabel} />
      {children}
    </div>
  );
};

export default ArraySchema;

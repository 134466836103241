import { FC, useMemo } from 'react';

import ArraySchema from './ArraySchema';
import FieldDetails from './FieldDetails';
import ObjectSchema from './ObjectSchema';
import OneOfSchema from './OneOfSchema';
import { prepareSchema } from './utils';
import { RawSchema, Schema as SchemaType } from './types';
import Examples from './Examples';

interface SchemaProps {
  schema: SchemaType;
  topLevel?: boolean;
}
interface SchemaWrapperProps {
  rawSchema?: RawSchema;
  schema?: SchemaType;
  topLevel?: boolean;
}

const Schema: FC<SchemaProps> = (props) => {
  const { schema, topLevel } = props;

  const { oneOf, type, examples = [] } = schema;

  const displayTopLevelExamples = topLevel && examples.length > 0;

  const children = displayTopLevelExamples ? (
    <Examples examples={examples} />
  ) : null;

  if (oneOf !== undefined) {
    return (
      <OneOfSchema schema={schema} topLevel={topLevel}>
        {children}
      </OneOfSchema>
    );
  }

  // eslint-disable-next-line default-case
  switch (type) {
    case 'object':
      return (
        <ObjectSchema schema={schema} topLevel={topLevel}>
          {children}
        </ObjectSchema>
      );
    case 'array':
      return (
        <ArraySchema schema={schema} topLevel={topLevel}>
          {children}
        </ArraySchema>
      );
  }

  const field = {
    schema,
    name: '',
    required: false,
    description: schema.description,
    externalDocs: schema.externalDocs,
    deprecated: false,
    expanded: false,
  };

  return (
    <div>
      <FieldDetails field={field} />
    </div>
  );
};

const SchemaWrapper: FC<SchemaWrapperProps> = (props) => {
  const { rawSchema, schema: preparedSchema, topLevel } = props;

  if (!preparedSchema && !rawSchema) {
    return <em> Schema not provided </em>;
  }

  const schema = useMemo(() => preparedSchema || prepareSchema(rawSchema!), [
    preparedSchema,
    rawSchema,
  ]);

  const { isCircular } = schema;

  if (isCircular) {
    return (
      <div>
        <span>{schema.displayType}</span>
        {schema.title && <span> {schema.title} </span>}
        <span> {'recursive'} </span>
      </div>
    );
  }

  return <Schema schema={schema} topLevel={topLevel} />;
};

export default SchemaWrapper;

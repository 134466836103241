import { FC } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { getInsetContainer } from '@mui-treasury/layout';
import { graphql, PageProps } from 'gatsby';
import Layout from '@labforward/gatsby-theme-laboperator-docs/src/components/Layout';
import Link from '@labforward/gatsby-theme-laboperator-docs/src/components/Link';

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import JsonSchemaDoc from './JsonSchemaDoc';
import SchemaSearch from './SchemaSearch';

/* 
  Git weblink will be moved here because when local fetch
  flag is set to true, gitRemote field is undefined
*/
const GIT_WEBLINK = 'https://github.com/labforward/laboperator-workflow-schema';

const InsetContainer = getInsetContainer(styled);

const useStyles = makeStyles((theme) => ({
  grow: { flexGrow: 1 },
  refHeading: {
    marginTop: theme.spacing(2),
  },
  noRef: {
    marginTop: theme.spacing(2),
    fontStyle: 'italic',
  },
}));

const SchemaPage: FC<
  PageProps<GatsbyTypes.SchemaPageQuery, GatsbyTypes.SitePageContext>
> = (props) => {
  const {
    location,
    pageContext,
    data: { schema, allSchema },
  } = props;
  const parsed = schema?.json ? JSON.parse(schema.json) : '';
  const classes = useStyles();

  return (
    <Layout pageContext={pageContext} location={location}>
      <InsetContainer maxWidth="lg">
        <Box m={4} width={1}>
          <Toolbar disableGutters variant="dense">
            <Button
              size="small"
              component={Link}
              href={`${GIT_WEBLINK}/tree/develop/${schema.parent.relativePath}`}
              target="_blank"
            >
              Edit on GitHub
            </Button>
            <div className={classes.grow} />
            <SchemaSearch />
          </Toolbar>
          <Typography variant="h3">{schema?.title}</Typography>
          <Typography variant="subtitle1" paragraph>
            A{' '}
            <Link href="https://json-schema.org/" target="_blank">
              schema
            </Link>{' '}
            that is used to validate a part of Laboperator{' '}
            <Link to="/workflow_schema_index/">workflow templates</Link>.
          </Typography>
          <JsonSchemaDoc rawSchema={parsed} />
          <Typography className={classes.refHeading} variant="h5">
            Where is this used?
          </Typography>
          <Typography variant="subtitle1">
            Other schemas that have a reference (<code>$ref</code>) to this
            schema.
          </Typography>
          {allSchema.nodes.length > 0 ? (
            <List>
              {allSchema.nodes.map(({ slug, title }) => (
                <ListItem component={Link} button to={slug} key={slug}>
                  <ListItemText primary={title} secondary={slug} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography className={classes.noRef} color="textSecondary">
              No references found.
            </Typography>
          )}
        </Box>
      </InsetContainer>
    </Layout>
  );
};

export const query = graphql`
  query SchemaPage($slug: String!) {
    schema(slug: { eq: $slug }) {
      json
      slug
      title
      parent {
        ... on File {
          relativePath
        }
      }
    }
    allSchema(filter: { references: { eq: $slug } }) {
      nodes {
        slug
        title
      }
    }
  }
`;

export default SchemaPage;

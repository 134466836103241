import { FC } from 'react';
import Box from '@material-ui/core/Box';
import Yaml from 'yaml';
import FieldLabel from './FieldLabel';

interface ExamplesProps {
  examples: unknown[];
}

const Examples: FC<ExamplesProps> = ({ examples }) => {
  return (
    <Box mt={2}>
      <FieldLabel variant="typePrefix">Examples:</FieldLabel>
      {examples.map((example, idx) => (
        <Box my={1} key={idx}>
          <Box
            className="gatsby-highlight"
            data-language="json"
            overflow="hidden"
          >
            <pre className="language-json">
              <code className="language-json">{Yaml.stringify(example)}</code>
            </pre>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Examples;

import { FC } from 'react';
import { endsWith, kebabCase } from 'lodash';
import Link from '@labforward/gatsby-theme-laboperator-docs/src/components/Link';

import useSchemaIndex from '../../hooks/useSchemaIndex';

interface SchemaReferenceProps {
  reference: string;
}

const SchemaReference: FC<SchemaReferenceProps> = ({ reference }) => {
  const path = reference.substring(2);
  const pathKebabCase = path.split('/').map(kebabCase).join('/');
  const schemaEntries = useSchemaIndex();
  const schemaEntry = schemaEntries.find(
    (entry) =>
      endsWith(entry.slug, `${path}/`) ||
      endsWith(entry.slug, `${pathKebabCase}/`)
  );

  if (schemaEntry) {
    return <Link to={schemaEntry.slug}>{schemaEntry.title}</Link>;
  } else if (reference === 'jsonSchemaDraft7') {
    return (
      <Link
        to="https://json-schema.org/specification-links.html#draft-7"
        target="_blank"
        rel="noopener noreferrer"
      >
        JSON Schema Draft 7
      </Link>
    );
  } else {
    return <span>{reference}</span>;
  }
};

export default SchemaReference;

import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@labforward/gatsby-theme-laboperator-docs/src/components/Link';
import Blockquote from './BlockQuote';

interface OptionsProps {
  classes: Record<string, unknown>;
  forceBlock: boolean;
  TypographyProps?: Record<string, unknown>;
}

interface CustomMarkdownProps {
  children: string;
  forceBlock?: boolean;
  TypographyProps?: Record<string, unknown>;
}

const markdownOptions = (props: OptionsProps): Record<string, unknown> => {
  const { forceBlock, TypographyProps } = props;

  return {
    forceBlock, // always wrap content in block element
    overrides: {
      p: {
        component: Typography,
        props: {
          paragraph: true,
          variant: 'body1',
          ...TypographyProps,
        },
      },
      table: {
        component: Table,
        props: {
          size: 'small',
        },
      },
      thead: {
        component: TableHead,
      },
      tbody: {
        component: TableBody,
      },
      tr: {
        component: TableRow,
      },
      td: {
        component: TableCell,
      },
      th: {
        component: TableCell,
      },
      hr: {
        component: Divider,
      },
      a: {
        component: Link,
      },
      blockquote: {
        component: Blockquote,
      },
    },
  };
};

const useStyles = makeStyles((theme) => ({
  markdown: {
    margin: theme.spacing(1, 0),
    '& p:last-child': {
      marginBottom: 0,
    },
    '& img[src*="fullwidth"]': {
      width: '100%',
    },
    '& img[src*="centered"]': {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      margin: 'auto',
    },
  },
}));

const CustomMarkdown: FC<CustomMarkdownProps> = ({
  forceBlock = false,
  children,
  TypographyProps = {},
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.markdown}>
      <Markdown
        options={markdownOptions({
          classes,
          forceBlock,
          TypographyProps,
        })}
        {...rest}
      >
        {children}
      </Markdown>
    </div>
  );
};

export default CustomMarkdown;

import { FC, useState, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import FieldDetails from './FieldDetails';
import FieldLabel from './FieldLabel';
import Schema from './Schema';
import { PreparedFieldSchema } from './types';

const useStyles = makeStyles((theme) => ({
  anchor: {
    '&:target::before': {
      content: '""',
      display: 'block',
      height: 80,
      marginTop: -80,
    },
  },
  propertyBullet: {
    color: 'border',
    fontFamily: '"Roboto Mono", monospace',
    marginRight: theme.spacing(2),
    '&::before': {
      content: '""',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: theme.spacing(2),
      height: '1px',
      backgroundColor: theme.palette.primary.main,
    },
    '&::after': {
      content: '""',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '1px',
      backgroundColor: theme.palette.primary.main,
      height: theme.spacing(1),
    },
  },
  propertyCell: {
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    position: 'relative',
    padding: theme.spacing(2, 2, 2, 0),
    'tr:first-of-type > &, tr.last > &': {
      borderLeftWidth: 0,
      backgroundPosition: 'top left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '1px 100%',
    },
    'tr:first-of-type > &': {
      backgroundImage: `linear-gradient(to bottom, transparent 0%, transparent 28px, ${theme.palette.primary.main} 28px, ${theme.palette.primary.main} 100% )`,
    },
    'tr.last > &': {
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 28px, transparent 28px, transparent 100% )`,
    },
    'tr.last + tr > &': {
      borderLeftColor: 'transparent',
    },
    'tr.last:first-child > &': {
      background: 'none',
      borderLeftColor: 'transparent',
    },
    '&.propertyCellWithInner': {
      padding: 0,
    },
    '&.propertyNameCell': {
      verticalAlign: 'top',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      fontFamily: '"Roboto Mono", monospace',
    },
  },
  propertyDetailsCell: {
    borderBottom: '1px solid #9fb4be',
    padding: theme.spacing(2),
    width: '75%',
    boxSizing: 'border-box',
    'tr.expanded &': {
      borderBottom: 'none',
    },
  },
  innerPropWrap: {
    padding: theme.spacing(3),
  },
}));

interface FieldProps {
  field: PreparedFieldSchema;
  isLast: boolean;
}

const Field: FC<FieldProps> = (props) => {
  const { field, isLast } = props;
  const { required, name, schema } = field;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const withSubSchema = !schema.isPrimitive && !schema.isCircular;

  return (
    <Fragment>
      <tr className={classnames({ last: isLast })}>
        <th
          className={classnames(classes.propertyCell, 'propertyNameCell')}
          onClick={
            withSubSchema ? (): void => setExpanded(!expanded) : undefined
          }
        >
          <div id={name} className={classes.anchor}></div>
          <span className={classes.propertyBullet} />
          {name}
          {withSubSchema && (
            <IconButton aria-label="expand row" size="small">
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
          {required && <FieldLabel variant="required">required</FieldLabel>}
        </th>
        <td className={classes.propertyDetailsCell}>
          <FieldDetails {...props} />
        </td>
      </tr>
      {expanded && withSubSchema && (
        <tr key={`${name}-sub-schema`}>
          <td
            colSpan={2}
            className={classnames(
              classes.propertyCell,
              'propertyCellWithInner'
            )}
          >
            <div className={classnames('innerPropWrap', classes.innerPropWrap)}>
              <Schema {...field} />
            </div>
          </td>
        </tr>
      )}
    </Fragment>
  );
};

export default Field;

import { FC, ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  fieldLabel: {
    '&.typePrefix, &.typeName': {
      color: theme.palette.text.secondary,
    },
    '&.typeTitle': {
      wordBreak: 'break-word',
    },
    '&.required': {
      color: theme.palette.primary.dark,
      fontWeight: 'normal',
      marginLeft: '20px',
      verticalAlign: 'top',
    },
    '&.recursive': {
      color: theme.palette.primary.dark,
      fontSize: '0.9em',
    },
    '&.nullable': {
      fontSize: '0.9em',
    },
    '&.exampleValue, &.pattern': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.primary.main, 0.15),
      color: theme.palette.primary.dark,
      margin: theme.spacing(0, 0.5),
      padding: theme.spacing(0, 0.5),
      border: '1px solid',
      borderColor: fade(theme.palette.primary.main, 0.1),
      fontFamily: '"Roboto Mono", monospace',
      '& + &': {
        marginLeft: 0,
      },
    },
  },
}));

interface FieldLabelProps {
  children: ReactNode;
  value?: ReactNode;
  variant?: string;
}

const FieldLabel: FC<FieldLabelProps> = (props) => {
  const { value, children, variant = '' } = props;
  const classes = useStyles();

  return (
    <span
      className={classnames(classes.fieldLabel, { [variant]: variant.length })}
    >
      {value || children}
    </span>
  );
};

export default FieldLabel;

import { FC, useMemo, ReactElement } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Fuse from 'fuse.js';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Link from '@labforward/gatsby-theme-laboperator-docs/src/components/Link';
import useSchemaIndex, { SchemaEntry } from '../hooks/useSchemaIndex';

const useStyles = makeStyles({
  input: {
    width: 300,
  },
});

const SchemaSearch: FC = () => {
  const schemaEntries = useSchemaIndex();
  const [options, fuse] = useMemo(
    () => [
      schemaEntries.map((doc, idx) => ({
        item: doc,
        score: 1,
        refIndex: idx,
      })) as Fuse.FuseResult<SchemaEntry>[],
      new Fuse(schemaEntries, {
        threshold: 0.4,
        keys: [{ name: 'title', weight: 2 }, 'slug'],
      }),
    ],
    [schemaEntries]
  );
  const classes = useStyles();

  return (
    <div>
      <Autocomplete
        freeSolo
        id="search-autocomplete"
        clearOnBlur
        clearOnEscape
        size="small"
        options={options}
        getOptionLabel={(option): string =>
          option?.item?.title || ((option as unknown) as string)
        }
        renderInput={(params): ReactElement => (
          <TextField
            {...params}
            className={classes.input}
            label="Go to schema"
            variant="outlined"
          />
        )}
        renderOption={({ item: { slug, title } }): ReactElement => (
          <Link to={slug} key={slug}>
            <ListItemText primary={title} secondary={slug} />
          </Link>
        )}
        filterOptions={(_options, { inputValue }) => fuse.search(inputValue)}
      />
    </div>
  );
};

export default SchemaSearch;

import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import CustomMarkdown from '../CustomMarkdown';
import { Schema as SchemaType } from './types';
import Schema from './Schema';
import { getDisplayType } from './utils';

const useStyles = makeStyles((theme) => ({
  oneOfLabel: {
    fontSize: '0.9em',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  oneOfList: {
    margin: theme.spacing(0, 0, 1, 0),
    padding: 0,
    listStyle: 'none',
    display: 'inline-block',
  },
  oneOfButton: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: '0.8em',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '2px 10px',
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FFFFFF',
    '&.active': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

interface OneOfSchemaProps {
  schema: SchemaType;
  topLevel?: boolean;
}

const OneOfSchema: FC<OneOfSchemaProps> = (props) => {
  const {
    schema: { oneOf },
    schema,
    topLevel,
    children,
  } = props;
  const classes = useStyles();
  const [activeOneOf, setActiveOneOf] = useState(0);

  if (oneOf === undefined) return null;

  return (
    <div>
      {topLevel && schema.description ? (
        <div>
          <CustomMarkdown>{schema.description}</CustomMarkdown>
        </div>
      ) : null}
      <span className={classes.oneOfLabel}> {schema.oneOfType} </span>
      <ul className={classes.oneOfList}>
        {oneOf.map((subSchema, idx) => (
          <li
            className={classnames(classes.oneOfButton, {
              active: activeOneOf === idx,
            })}
            key={subSchema.pointer}
            onClick={(): void => setActiveOneOf(idx)}
          >
            {getDisplayType(subSchema)}
          </li>
        ))}
      </ul>
      <Schema schema={oneOf[activeOneOf]} />
      {children}
    </div>
  );
};

export default OneOfSchema;
